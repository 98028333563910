import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import Text from './Text';
import Asset from './Asset';
import CTA from './CTA';
import ImageGallery from './ImageGallery';

import './text-block.less';

const TextBlock = ({ textBlock, background }) => {
  const className = useClassName('ModularTextBlock');

  const getContainerExtraClasses = () => {
    const extraClasses = [];
    if (textBlock.isHero) extraClasses.push('container-hero');
    if (
      !textBlock.isHero &&
      (textBlock.backgroundColor || textBlock.backgroundSecondaryColor)
    ) {
      extraClasses.push('container-rounded');
    }
    return extraClasses;
  };

  const getContentExtraClasses = () => {
    const extraClasses = [];
    if (textBlock.slider) extraClasses.push('content-extra-padding');
    if (textBlock?.assetPosition === 'logo')
      extraClasses.push('content-top-aligned');
    if (textBlock.asset && textBlock?.assetPosition === 'left')
      extraClasses.push('content-reverse');
    if (
      !textBlock.isHero &&
      (textBlock.backgroundColor || textBlock.backgroundSecondaryColor)
    ) {
      extraClasses.push('content-rounded');
    }
    return extraClasses;
  };

  const getTextContainerExtraClasses = () => {
    const extraClasses = [];
    if (
      !textBlock.asset ||
      textBlock.slider ||
      textBlock?.assetPosition === 'logo'
    )
      extraClasses.push('text-container-large');
    if (
      (!textBlock.asset ||
        textBlock.slider ||
        textBlock?.assetPosition === 'logo') &&
      textBlock.textAlign
    )
      extraClasses.push(`text-container-align-${textBlock.textAlign}`);
    if (
      !textBlock.isHero &&
      (textBlock.backgroundColor || textBlock.backgroundSecondaryColor)
    ) {
      extraClasses.push('text-container-padding');
    }
    return extraClasses;
  };

  const getTextContentExtraClasses = () => {
    const extraClasses = [];
    if (!textBlock.asset) extraClasses.push('text-content-short');
    if (textBlock.slider || textBlock?.assetPosition === 'logo')
      extraClasses.push('text-content-large');
    if (textBlock.textAlign) {
      extraClasses.push(`text-content-align-${textBlock.textAlign}`);
    }
    return extraClasses;
  };

  const getAssetContainerExtraClasses = () => {
    const extraClasses = [];
    if (textBlock.slider || textBlock?.assetPosition === 'logo')
      extraClasses.push('asset-container-small');
    return extraClasses;
  };

  return (
    <div className={className(['container', ...getContainerExtraClasses()])}>
      <div
        className={className('inner-container')}
        style={{
          background,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <div className={className(['content', ...getContentExtraClasses()])}>
          <div
            className={className([
              'text-container',
              ...getTextContainerExtraClasses(),
            ])}>
            <div
              className={className([
                'text-content',
                ...getTextContentExtraClasses(),
              ])}>
              {textBlock.overline && (
                <div className={className('overline')}>
                  <Text
                    key="overline"
                    text={textBlock.overline}
                    textAlign={textBlock.textAlign}
                  />
                </div>
              )}
              {textBlock.title && (
                <div className={className('title')}>
                  <Text
                    key="title"
                    text={textBlock.title}
                    textAlign={textBlock.textAlign}
                  />
                </div>
              )}
              {textBlock.body && (
                <div className={className('body')}>
                  <Text
                    key="body"
                    text={textBlock.body}
                    textAlign={textBlock.textAlign}
                  />
                </div>
              )}
              {textBlock?.cta?.length &&
                textBlock.cta.map((cta, index) => (
                  <CTA key={`cta-${index}`} cta={cta} />
                ))}
            </div>
          </div>

          {textBlock.asset && (
            <div
              className={className([
                'asset-container',
                ...getAssetContainerExtraClasses(),
              ])}>
              <Asset asset={textBlock.asset} />
            </div>
          )}
        </div>
      </div>

      {textBlock.slider && (
        <div className={className('slider-container')}>
          <ImageGallery imageGallery={textBlock.slider} />
        </div>
      )}
    </div>
  );
};

TextBlock.propTypes = {
  textBlock: PropTypes.object.isRequired,
};

export default TextBlock;
